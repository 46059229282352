import React from 'react';

import './LoadingSimple.scss';

const LoadingSimple = () => {
  return (
    <>
      <div className="simple-loading-animation">
        <span></span>
        <span></span>
        <span></span>
      </div>
    </>
  );
};

export default LoadingSimple;
