import React, { ReactElement } from 'react';
import { LoadingType, LoadingIconType } from '@incentivegames/ig-types/lib/enums/loading/loading';

import LoadingDefault from './LoadingDefault';
import LoadingSimple from './LoadingSimple';
import LoadingCustom from './LoadingCustom';
import LoadingSpinner from './LoadingSpinner';

export interface ILoadingProps {
  loadingType: LoadingType;
  loadingText?: string;
  loadingIconType?: LoadingIconType;
  children?: ReactElement | ReactElement<any>[];
}

export const Loading = (props: ILoadingProps) => {
  switch (props.loadingType) {
    case LoadingType.simple:
      return <LoadingSimple />;
    case LoadingType.custom:
      return <LoadingCustom>{props.children}</LoadingCustom>;
    case LoadingType.spinner:
      return <LoadingSpinner />;
    default:
      return <LoadingDefault loadingText={props.loadingText} loadingIconType={props.loadingIconType} />;
  }
};
