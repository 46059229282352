import React, { ReactElement } from 'react';

export interface ILoadingCustomProps {
  children?: ReactElement | ReactElement<any>[];
}

const LoadingCustom = (props: ILoadingCustomProps) => {
  return (
    <div className="loading-custom__container">
      {props.children ? 
        props.children :
        <div className="loading-custom__animation" />
      }
    </div>
  );
};

export default LoadingCustom;
