import React from 'react';

import './LoadingSpinner.scss';

const LoadingSpinner = () => {
  return (
    <div className="loading-spinner__container">
      <div className="loading-spinner__animation" />
    </div>
  );
};

export default LoadingSpinner;