import React from 'react';
import { useTranslation } from 'react-i18next';

import iconSprite from '../../../storyData/icons-sprites.svg';

import './LoadingDefault.scss';
import { LoadingIconType } from '@incentivegames/ig-types/lib/enums/loading/loading';

export interface ILoadingDefaultProps {
  loadingText?: string;
  loadingIconType?: LoadingIconType;
}

const LoadingDefault = (props: ILoadingDefaultProps) => {
  const { t } = useTranslation();
  const iconId = props.loadingIconType ? props.loadingIconType : LoadingIconType.football;

  return (
    <div className="loading">
      <div className="loading__loader-wrap">
        <div className="loading__anim">
          <div className="loading__squeeze">
            <svg className="loading__ball-anim">
              <use xlinkHref={`${iconSprite}#${iconId}`} />
            </svg>
          </div>
        </div>
        <div className="loading__shadow"></div>
        <div className="loading__text">{t(`${props.loadingText || 'loading'}`)}</div>
      </div>
    </div>
  );
};

export default LoadingDefault;
